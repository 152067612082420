import { useState, useMemo, useEffect } from 'react'
import { Breadcrumb, Form, Col, Divider, Row } from 'antd'
import { Link, Redirect } from 'react-router-dom'

import styled from 'styled-components'

import { AuthorizedLayout } from 'components/AuthorizedLayout'
import { CreateArticleOptions } from 'services/Articles/types'
import { FormAddEdit } from './components/addEditForm'
import useServices from 'hooks/useServices'
import { ROUTE } from 'routers/types'

const StyledRow = styled(Row)`
  .ant-form-item-control {
    max-width: 700px;
  }

  .ant-form-item-label {
    label {
      margin: auto;
      font-weight: 600;
    }
  }
`

const AdminBlogArticlesAddRoute = () => {
  const { articles } = useServices()

  const [form] = Form.useForm<CreateArticleOptions>()

  const [redirectToList, setRedirectToList] = useState(false)

  const onFinish = (values: CreateArticleOptions) => {
    articles
      .create(values)
      .then(articleCreationStatus => {
        if (!articleCreationStatus) {
          throw new Error('Invalid article data')
        }

        setRedirectToList(true)
      })
      .catch((err: string) => {
        return alert(err)
      })
  }

  if (redirectToList) {
    return <Redirect to={ROUTE.ADMIN_BLOG_ARTICLES_LIST} />
  }

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }} xl={{ span: 24, offset: 1 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Add new Article</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <StyledRow>
        <Col xs={{ span: 20, offset: 2 }} xl={{ span: 24, offset: 1 }}>
          <FormAddEdit form={form} onFinish={onFinish} />
        </Col>
      </StyledRow>
    </AuthorizedLayout>
  )
}

export default AdminBlogArticlesAddRoute
