import { CreateArticleOptions } from './types'

export const stringToSlug = (slugStr: string) => {
  slugStr = slugStr.replace(/^\s+|\s+$/g, '') // trim
  slugStr = slugStr.toLowerCase()

  var from = 'ąåàáãäâęèéëêìíïîòóöôùúüûñçźżńłść·/_,:;'
  var to = 'aaaaaaaeeeeeiiiioooouuuunczznlsc------'

  for (var i = 0, l = from.length; i < l; i++) {
    slugStr = slugStr.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  slugStr = slugStr
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return slugStr
}

export const validateArticleChanges = (values: CreateArticleOptions) => {
  switch (true) {
    case values.authorName.length < 1:
      throw new Error('Author name is too short')

    case values.authorName.length < 1:
      throw new Error('Author name is too short')

    case values.authorSurname.length < 1:
      throw new Error('Author surname is too short')

    case values.authorShortDescription.length < 3:
      throw new Error('Short description  is too short')
    case values.title.length < 10:
      throw new Error('Title  is too short')
    case values.text.length < 100:
      throw new Error('Text is too short')
  }
}
