export type CurrentUser = {
  email: string
  uid: string
  isAdmin?: boolean
  isExercisesModerator?: boolean
}

export type Model<T> = {
  objectID: string
} & T

export interface Success<T> {
  status: 'success'
  payload: T
}

export interface Loading {
  status: 'loading'
}

export interface Error {
  status: 'error'
  payload: StateError
}

export enum FirebaseError {
  AuthEmailAlreadyInUse,
  AuthWrongPassword,
  AuthUserDisabled,
  AuthUserNotFound,
  AuthCredentialAlreadyInUse,
  AuthTooManyRequests,
  AuthExistWithDifferentCredential,
  AuthInvalidVerificationCode,
  AuthInvalidPhoneNumber,
  AuthInvalidEmailAddress,
  AuthUnknownError
}

export enum AuthError {
  UserNotLogged = 'auth/user-not-logged',
  UserNotFound = 'auth/user-not-found',
  TermsOfServicesRequired = 'auth/terms-of-services-required',
  InvalidEmail = 'auth/invalid-email',
  Cancelled = 'auth/cancelled',
  DeclinedPermissions = 'auth/declined-permissions'
}

export enum CustomError {
  UserNotFound = 'custom/user-not-found',
  ExerciseAlreadyExist = 'exercise/already-exist',
  ExerciseCreateFieldsAreInvalid = 'exercise/create-fields-invalid',
  CalendarExerciseNotFound = 'calendar/exercise-not-found',
  TrainingNotFound = 'traning/not-found',
  TrainingExerciseNotFound = 'training/exercise-not-found',
  NutritionCalendarDayNotFound = 'nutrition/nutrition-calendar-day-not-found',
  NutritionCalendarDayProductNotFound = 'nutrition/nutrition-calendar-day-product-not-found'
}

export type StateError = FirebaseError | AuthError | CustomError
