import React from 'react'

import Api from './Api'
import Auth from './Auth'
import Exercises from './Exercises'
import FoodProducts from './FoodProducts'
import FoodProductsReports from './FoodProductsReports'
import Articles from './Articles'

class Services {
  auth: Auth
  exercises: Exercises
  foodProducts: FoodProducts
  foodProductsReports: FoodProductsReports
  articles: Articles

  constructor() {
    const api = new Api()
    this.auth = new Auth(api)
    this.exercises = new Exercises(api)
    this.foodProducts = new FoodProducts(api)
    this.foodProductsReports = new FoodProductsReports(api)
    this.articles = new Articles()
  }
}

const Context = React.createContext<Services>(undefined as any)
const Provider = Context.Provider
const makeServices = (): Services => new Services()

export { Services, makeServices, Provider, Context }
