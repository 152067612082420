import React from 'react'
import { Button, Card, List, Typography, Modal, Space, Input } from 'antd'
import { format, formatDistanceToNow } from 'date-fns'
import { EyeOutlined, ReloadOutlined } from '@ant-design/icons'

import { FoodProductReportData } from 'services/FoodProductsReports/types'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'
import useEventHandler from 'hooks/useEventHandler'
import { EMPTY } from 'rxjs'

const ProductsReportsList = () => {
  const { foodProductsReports } = useServices()

  const [productsReportsLoading, setProductsReportsLoading] = React.useState(true)
  const [productsReportsData, setProductsReportsData] = React.useState<FoodProductReportData[]>([])

  const [productDetails, setProductDetails] = React.useState<FoodProductReportData>()
  const [productDetailsLoading, setProductDetailsLoading] = React.useState<boolean>()

  const [resolveComment, setResolveComment] = React.useState('')

  React.useEffect(() => {
    const productsReportSubscriber = foodProductsReports
      .getList(10, 0, 'createdAt', 'descend', true)
      .pipe(toResult())
      .subscribe(state => {
        setProductsReportsLoading(state.status === 'loading')
        if (state.status === 'success') {
          setProductsReportsData(state.payload.data)
        }
      })

    return () => productsReportSubscriber.unsubscribe()
  }, [foodProductsReports])

  const handleRefreshPress = useEventHandler(() => {
    return foodProductsReports
      .getList(10, 0, 'createdAt', 'descend', true, true)
      .pipe(toResult())
      .subscribe(state => {
        setProductsReportsLoading(state.status === 'loading')
        if (state.status === 'success') {
          setProductsReportsData(state.payload.data)
        }
      })
  })

  const handleModalResolve = useEventHandler(() => {
    if (!productDetails) {
      return EMPTY.subscribe()
    }

    return foodProductsReports
      .resolve(productDetails.objectID, true, resolveComment)
      .pipe(toResult())
      .subscribe(state => {
        setProductDetailsLoading(state.status === 'loading')
        if (state.status === 'success') {
          setProductsReportsData(
            productsReportsData.map(x => {
              if (x.objectID === productDetails.objectID) {
                return {
                  ...x,
                  isResolved: true,
                  resolveComment
                }
              }

              return x
            })
          )
          setProductDetails(undefined)
        }
      })
  }, [productsReportsData, productDetails, resolveComment])

  return (
    <Card
      title="Food Products Reports"
      extra={
        <Button onClick={handleRefreshPress}>
          <ReloadOutlined />
        </Button>
      }
    >
      <List
        dataSource={productsReportsData}
        loading={productsReportsLoading}
        renderItem={item => (
          <List.Item
            style={item.isResolved ? { opacity: 0.25 } : {}}
            actions={[
              <Button onClick={() => setProductDetails(item)}>
                <EyeOutlined />
              </Button>
            ]}
          >
            <List.Item.Meta
              title={<Typography.Text strong>{item.name}</Typography.Text>}
              description={
                !item.createdAt
                  ? '-'
                  : `${format(new Date(item.createdAt), 'dd MMMM yyyy')} - ${formatDistanceToNow(
                      new Date(item.createdAt)
                    )}`
              }
            />
          </List.Item>
        )}
      />
      <Modal
        title="Product report"
        visible={Boolean(productDetails)}
        onCancel={() => setProductDetails(undefined)}
        footer={
          <Space>
            <Button type="default" onClick={() => setProductDetails(undefined)}>
              Cancel
            </Button>
            <Button type="primary" loading={productDetailsLoading} onClick={handleModalResolve}>
              Mark as resolved
            </Button>
          </Space>
        }
      >
        {!productDetails ? null : (
          <>
            <Typography.Title level={3}>
              <strong>{productDetails.name}</strong>
            </Typography.Title>

            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Space direction="vertical">
                <Space align="center">
                  <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                    Report date:
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    <strong>
                      {productDetails.createdAt ? format(new Date(productDetails.createdAt), 'dd MMMM yyyy') : '-'}
                    </strong>
                  </Typography.Paragraph>
                </Space>
                <Space align="center">
                  <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                    Product language:
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    <strong>{productDetails.lang}</strong>
                  </Typography.Paragraph>
                </Space>
                <Space align="center">
                  <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                    Barcode
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    <strong>{productDetails.barcode || '-'}</strong>
                  </Typography.Paragraph>
                </Space>
                <Space align="center">
                  <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                    OpenFoodFact ID:
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    <strong>{productDetails.openFoodId || '-'}</strong>
                  </Typography.Paragraph>
                </Space>
                <Space align="center">
                  <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                    Custom Product ID:
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    <strong>{productDetails.productId || '-'}</strong>
                  </Typography.Paragraph>
                </Space>
                <Space align="center">
                  <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                    JSON ID:
                  </Typography.Paragraph>
                  <Typography.Paragraph style={{ marginBottom: 0 }}>
                    <strong>{productDetails.jsonProductId || '-'}</strong>
                  </Typography.Paragraph>
                </Space>
              </Space>

              <Space direction="vertical">
                <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                  Issue comment:
                </Typography.Paragraph>
                <Typography.Paragraph style={{ marginTop: 0 }}>{productDetails.issue}</Typography.Paragraph>
              </Space>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Paragraph style={{ marginBottom: 0 }} type="secondary">
                  Resolve with comment:
                </Typography.Paragraph>
                <Input.TextArea
                  defaultValue={productDetails.resolveComment}
                  onChange={ev => setResolveComment(ev.target.value)}
                  style={{ width: '100%', height: '100px' }}
                />
              </Space>
            </Space>
          </>
        )}
      </Modal>
    </Card>
  )
}

export default ProductsReportsList
