import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Typography,
  Form,
  Pagination,
  Select
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import { FoodProductData } from 'services/FoodProducts/types'
import useEventHandler from 'hooks/useEventHandler'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'
import { ROUTE } from 'routers/types'

import { AuthorizedLayout } from 'components/AuthorizedLayout'

const AdminFoodProductsRoute = () => {
  const { foodProducts } = useServices()

  const [data, setData] = useState<FoodProductData[]>([])
  const [isLoading, setLoading] = useState(true)

  const [idEditing, setIdEditing] = useState<string>()
  const [brandEditing, setBrandEditing] = useState<string>()

  const [filterBrandsToVerify, setFilterBrandsToVerify] = useState('all')

  const pageSize = 50
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState<number>()

  useEffect(() => {
    const subscriber = foodProducts
      .getJsonList(pageSize, pageSize * page - pageSize, 'ascend', filterBrandsToVerify)
      .pipe(toResult())
      .subscribe(state => {
        setLoading(state.status === 'loading')
        if (state.status === 'success') {
          setData(state.payload.data)
          setTotal(state.payload.total)
        }
      })

    return () => subscriber.unsubscribe()
  }, [foodProducts, page, filterBrandsToVerify])

  const handleRemovePress = useEventHandler(
    (objectID: string) => {
      return foodProducts
        .deleteJson(objectID)
        .pipe(toResult())
        .subscribe(state => {
          setLoading(state.status === 'loading')
          if (state.status === 'success') {
            setData(data.filter(item => item.objectID !== objectID))
          }
        })
    },
    [data]
  )

  const handleSaveSetBrand = useEventHandler(
    (object: FoodProductData, brand: string, removeFromName: boolean) => {
      const updatedObject = { ...object, brand: brand.trim() }
      if (removeFromName) {
        updatedObject.name = updatedObject.name
          .toLocaleLowerCase()
          .replace(brand.trim().toLocaleLowerCase(), '')
          .replace('  ', ' ')
          .trim()
        // @ts-ignore
        updatedObject.brandToVerify = false
      }

      return foodProducts
        .updateJson(object.objectID, updatedObject)
        .pipe(toResult())
        .subscribe(state => {
          setLoading(state.status === 'loading')
          if (state.status === 'error') {
            alert('Failed, some error on server side')
          }

          if (state.status === 'success') {
            setBrandEditing(undefined)
            setIdEditing(undefined)
            setData(
              data.map(item => {
                if (item.objectID === object.objectID) {
                  return updatedObject
                }

                return item
              })
            )
          }
        })
    },
    [data]
  )

  const handleSwitchBrandVerified = useEventHandler(
    (object: FoodProductData, brandToVerify: boolean) => {
      // @ts-ignore
      const updatedObject: FoodProductData = { ...object, brandToVerify }

      return foodProducts
        .updateJson(object.objectID, updatedObject)
        .pipe(toResult())
        .subscribe(state => {
          setLoading(state.status === 'loading')
          if (state.status === 'error') {
            alert('Failed, some error on server side')
          }

          if (state.status === 'success') {
            setBrandEditing(undefined)
            setIdEditing(undefined)
            setData(
              data.map(item => {
                if (item.objectID === object.objectID) {
                  return updatedObject
                }

                return item
              })
            )
          }
        })
    },
    [data]
  )

  const handleSwitchToVerified = useEventHandler(
    (object: FoodProductData, toVerify: boolean) => {
      const updatedObject: FoodProductData = { ...object, toVerify }

      return foodProducts
        .updateJson(object.objectID, updatedObject)
        .pipe(toResult())
        .subscribe(state => {
          setLoading(state.status === 'loading')
          if (state.status === 'error') {
            alert('Failed, some error on server side')
          }

          if (state.status === 'success') {
            setBrandEditing(undefined)
            setIdEditing(undefined)
            setData(
              data.map(item => {
                if (item.objectID === object.objectID) {
                  return updatedObject
                }

                return item
              })
            )
          }
        })
    },
    [data]
  )

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Food products</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <Space direction="vertical">
        <Row>
          <Col xs={{ span: 20, offset: 2 }}>
            <Space size="large" style={{ float: 'right' }}>
              <Form.Item label="Brands:">
                <Select
                  defaultValue="all"
                  onChange={value => setFilterBrandsToVerify(value as string)}
                  style={{ width: '100px' }}
                >
                  <Select.Option value="all">all</Select.Option>
                  <Select.Option value="to-verify">to verify</Select.Option>
                  <Select.Option value="verified">verified</Select.Option>
                </Select>
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 20, offset: 2 }}>
            <Table pagination={false} rowKey="objectID" loading={isLoading} dataSource={data}>
              <Table.Column
                dataIndex="name"
                title="Name"
                render={(value: string) => (
                  <Typography.Text strong style={{ maxWidth: '150px', display: 'block' }}>
                    {value}
                  </Typography.Text>
                )}
              />
              <Table.Column
                dataIndex="brand"
                title="Brand"
                render={(value: string, record: FoodProductData) => (
                  <Space style={{ width: '120px' }}>
                    {idEditing === record.objectID ? (
                      <Space direction="vertical">
                        <Input
                          value={brandEditing}
                          placeholder="brand"
                          onChange={e => setBrandEditing(e.target.value)}
                        />
                        {/* <Space size="middle"> */}
                        <Typography.Link strong onClick={() => handleSaveSetBrand(record, brandEditing || '', true)}>
                          Update/name
                        </Typography.Link>
                        <Typography.Link strong onClick={() => handleSaveSetBrand(record, brandEditing || '', false)}>
                          Update
                        </Typography.Link>
                        <Typography.Link type="danger" strong onClick={() => setIdEditing(undefined)}>
                          Cancel
                        </Typography.Link>
                        {/* </Space> */}
                      </Space>
                    ) : (
                      <Typography.Link
                        type={!Boolean(value) ? 'danger' : undefined}
                        onClick={() => {
                          setIdEditing(record.objectID)
                          setBrandEditing(record.brand)
                        }}
                      >
                        {value || 'edit brand'}
                      </Typography.Link>
                    )}
                  </Space>
                )}
              />
              <Table.Column
                dataIndex="brandToVerify"
                title="Brand to verify"
                render={(value: boolean, record: FoodProductData) => (
                  <Switch
                    // @ts-ignore
                    checked={value}
                    onChange={checked => handleSwitchBrandVerified(record, checked)}
                  />
                )}
              />
              <Table.Column
                dataIndex="toVerify"
                title="to verify"
                render={(value: boolean, record: FoodProductData) => (
                  <Switch
                    // @ts-ignore
                    checked={value}
                    onChange={checked => handleSwitchToVerified(record, checked)}
                  />
                )}
              />
              <Table.Column
                dataIndex="energyValue"
                title="Nutritions"
                render={(value: string, record: FoodProductData) => (
                  <Space direction="vertical" size="small">
                    <Typography.Text>{value} kcal</Typography.Text>
                    <Typography.Text>
                      {record.proteins} / {record.fats} / {record.carbohydrates}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column
                dataIndex="createdAt"
                title="Created Date"
                render={(value: string) => format(new Date(value), 'dd MMM yyyy')}
              />
              <Table.Column
                dataIndex="updatedAt"
                title="Updated Date"
                render={(value?: string) => (value ? format(new Date(value), 'dd MMM yyyy') : null)}
              />
              <Table.Column
                // sorter={true}
                dataIndex="actions"
                title="Actions"
                render={(_value: any, record: FoodProductData) => (
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Space>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleRemovePress(record.objectID)}
                        okText="Remove"
                        cancelText="Cancel"
                        okButtonProps={{ danger: true }}
                      >
                        <Button danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Space>
                  </div>
                )}
              />
            </Table>
          </Col>
        </Row>
        <Row justify="end" style={{ marginBottom: 48 }}>
          <Col xs={{ pull: 2 }}>
            <Pagination
              current={page}
              total={total}
              showQuickJumper={true}
              showSizeChanger={false}
              pageSize={pageSize}
              onChange={setPage}
            />
          </Col>
        </Row>
      </Space>
    </AuthorizedLayout>
  )
}

export default AdminFoodProductsRoute
