import React from 'react'
import { Breadcrumb, Col, Divider, Row, Typography } from 'antd'

import { AuthorizedLayout } from 'components/AuthorizedLayout'

import FoodProductsToVerifyList from './components/productsToVerifyList'
import FoodProductsReportsList from './components/productsReportsList'
import { useCurrentUser } from 'hooks/useCurrentUser'

const AdminDashboardRoute = () => {
  const { currentUser } = useCurrentUser()
  return (
    <AuthorizedLayout>
      {currentUser?.isAdmin ? (
        <Row style={{ margin: '32px 0 8px 0' }}>
          <Col xs={{ span: 20, offset: 2 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Typography.Title level={3}>FitMate Dashboard</Typography.Title>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      ) : null}
      <Divider />

      {currentUser?.isAdmin ? (
        <Row style={{ margin: '0 24px' }}>
          <Col span={8} style={{ paddingRight: '4px' }}>
            <FoodProductsToVerifyList />
          </Col>
          <Col span={8} style={{ paddingLeft: '4px', paddingRight: '4px' }}>
            <FoodProductsReportsList />
          </Col>
        </Row>
      ) : null}
    </AuthorizedLayout>
  )
}

export default AdminDashboardRoute
