import { omit } from 'remeda'
import { ROUTE } from 'routers/types'
import { from, throwError } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import Api from 'services/Api'
import { FoodProductData } from './types'

class Exercises {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  getList(
    limit = 10,
    offset = 0,
    sort?: keyof FoodProductData,
    order: 'ascend' | 'descend' = 'ascend',
    toVerify?: boolean,
    forceCacheClear?: boolean
  ) {
    const searchParams = new URLSearchParams({ limit: limit.toString(), offset: offset.toString() })
    if (sort && order) {
      searchParams.append('sort', sort)
      searchParams.append('order', order)
    }

    toVerify && searchParams.append('to-verify', '1')
    forceCacheClear && searchParams.append('no-cache', '1')

    const query = searchParams.toString()
    return from(this.api.fetchAuth(`/food-products?${query}`, { method: 'GET' })).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  getOne(id: string) {
    return from(this.api.fetchAuth(`/food-products/${id}`, { method: 'GET' })).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  update(objectId: string, data: FoodProductData) {
    if (
      !data.objectID ||
      !data.createdAt ||
      !data.createdBy ||
      !data.name ||
      !data.valuesPerUnit ||
      !data.energyValue ||
      data.proteins === undefined ||
      data.proteins === null ||
      data.fats === undefined ||
      data.fats === null ||
      data.carbohydrates === undefined ||
      data.carbohydrates === null
    ) {
      console.log(data)
      return throwError(new Error('Invalid update food product data'))
    }

    return from(
      this.api.fetchAuth(`/food-products/${objectId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          ...omit(data, ['objectID']),
          updatedAt: new Date()
        })
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  create(data: FoodProductData) {
    if (
      !data.objectID ||
      !data.createdAt ||
      !data.createdBy ||
      !data.name ||
      !data.valuesPerUnit ||
      !data.energyValue ||
      data.proteins === undefined ||
      data.proteins === null ||
      data.fats === undefined ||
      data.fats === null ||
      data.carbohydrates === undefined ||
      data.carbohydrates === null
    ) {
      throw new Error('Invalid update food product data')
    }

    return from(
      this.api.fetchAuth(`/food-products`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          ...omit(data, ['objectID']),
          isPublic: data.isPublic
        })
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  delete(id: string) {
    return from(
      this.api.fetchAuth(`/food-products/${id}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json'
        }
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  getJsonList(limit = 10, offset = 0, order: 'ascend' | 'descend' = 'ascend', filterBrandsToVerify = 'all') {
    const searchParams = new URLSearchParams({ limit: limit.toString(), offset: offset.toString() })
    if (order) {
      searchParams.append('order', order)
    }

    console.log(filterBrandsToVerify)
    if (filterBrandsToVerify) {
      searchParams.append('brands-to-verify', filterBrandsToVerify)
    }

    const query = searchParams.toString()
    return from(this.api.fetchAuth(`/food-products/json-list?${query}`, { method: 'GET' })).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  updateJson(objectId: string, data: FoodProductData) {
    if (
      data.objectID === undefined ||
      !data.name ||
      !data.energyValue ||
      data.proteins === undefined ||
      data.proteins === null ||
      data.fats === undefined ||
      data.fats === null ||
      data.carbohydrates === undefined ||
      data.carbohydrates === null
    ) {
      console.log(data)
      throw new Error(`Invalid update food product data`)
    }

    return from(
      this.api.fetchAuth(`/food-products/json-list/${objectId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          ...omit(data, ['objectID']),
          updatedAt: new Date()
        })
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  deleteJson(id: string) {
    return from(
      this.api.fetchAuth(`/food-products/json-list/${id}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json'
        }
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }
}

export default Exercises
