import React from 'react'
import { Checkbox, Col, Divider, Form, Input, Radio, Row, Spin, Switch, Tabs } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { Categories, ExerciseData, Forces, Tags } from 'services/Exercises/types'

interface Props {
  form: FormInstance<ExerciseData>
  onFinish: (values: ExerciseData) => void
  isUpdating: boolean
  children?: React.ReactElement
}

const ExerciseForm = (props: Props) => {
  const { form, onFinish, isUpdating, children } = props

  const handleFinish = (values: ExerciseData) => {
    if (!values.locales?.en?.name) {
      return
    }

    values.name = values.locales.en.name
    values.description = values.locales.en.description

    onFinish(values)
  }

  return (
    <Form form={form} name="control-hooks" onFinish={handleFinish}>
      <Row>
        <Col xs={{ span: 8, offset: 0 }} style={{ maxWidth: '300px' }}>
          <Form.Item
            labelCol={{ xs: { span: 8 } }}
            label="Force"
            name="force"
            rules={[{ required: true, message: 'Field required' }]}
          >
            <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
              {Forces.map((force, index) => (
                <Radio value={force} key={index}>
                  {force}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            labelCol={{ xs: { span: 8 } }}
            label="Category"
            name="category"
            rules={[{ required: true, message: 'Field required' }]}
          >
            <Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
              {Categories.map((category, index) => (
                <Radio value={category} key={index}>
                  {category}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={{ span: 8 }} style={{ maxWidth: '300px' }}>
          <Form.Item
            labelCol={{ xs: { span: 8 } }}
            label="Tags"
            name="tags"
            rules={[{ required: true, message: 'Field required' }]}
          >
            <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={Tags} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Form.Item labelCol={{ xs: { span: 3 } }} label="Public" name="isPublic" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item labelCol={{ xs: { span: 3 } }} label="Details url" name={'detailsUrl'}>
        <Input />
      </Form.Item>
      <Divider />
      <Row>
        <Col xs={{ span: 20, offset: 2 }}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="EN" key="1">
              <Form.Item
                labelCol={{ xs: { span: 4 } }}
                label="Name (EN)"
                name={['locales', 'en', 'name']}
                rules={[{ required: true, message: 'Field required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                labelCol={{ xs: { span: 4 } }}
                label="Description (EN)"
                name={['locales', 'en', 'description']}
              >
                <Input.TextArea rows={10} />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane tab="PL" key="2">
              <Form.Item labelCol={{ xs: { span: 4 } }} label="Name (PL)" name={['locales', 'pl', 'name']}>
                <Input />
              </Form.Item>
              <Form.Item
                labelCol={{ xs: { span: 4 } }}
                label="Description (PL)"
                name={['locales', 'pl', 'description']}
              >
                <Input.TextArea rows={10} />
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

      <Form.Item wrapperCol={{ xs: { offset: 4 } }}>{isUpdating ? <Spin /> : children}</Form.Item>
    </Form>
  )
}

export default ExerciseForm
