import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Divider, Row, Space, Table, Button, Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined, FormatPainterFilled } from '@ant-design/icons'
import { generatePath, Link } from 'react-router-dom'

import { ArticleData, CreateArticleOptions } from 'services/Articles/types'
import { ROUTE } from 'routers/types'

import { AuthorizedLayout } from 'components/AuthorizedLayout'
import { Spinner } from './components/spinners'
import useServices from 'hooks/useServices'

import { format } from 'date-fns'

const AdminBlogArticlesListRoute = () => {
  const { articles } = useServices()
  const [data, setData] = useState<ArticleData[]>([])
  const [isDataLoaded, setDataLoaded] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const handleRemovePress = (id: string) => {
    setLoading(true)

    articles.delete(id).then(isRemoved => {
      if (isRemoved) {
        setDataLoaded(false)
      }
    })
  }

  useEffect(() => {
    if (isDataLoaded) {
      return
    }

    articles.getList().then(articleGetListStatus => {
      if (!articleGetListStatus) {
        return
      }
      setDataLoaded(true)
      setLoading(false)
      setData(articleGetListStatus)
    })
  }, [isDataLoaded])

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Blog Articles</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <Space direction="vertical">
        <Row>
          <Col xs={{ span: 20, offset: 2 }}>
            {isLoading ? (
              <Spinner isSpinning={true} />
            ) : (
              <Table pagination={false} dataSource={data} rowKey="objectID">
                <Table.Column
                  title="Title"
                  dataIndex="title"
                  key="title"
                  render={(text: string) => (
                    <a href="" target="_blank">
                      {text}
                    </a>
                  )}
                />
                <Table.Column
                  title="Author"
                  dataIndex={['author', 'name']}
                  key="author"
                  render={(value: string, record: ArticleData) => {
                    if (record?.author && record.author?.name && record.author?.surname) {
                      return `${record?.author.name} ${record?.author.surname}`
                    }
                    return value
                  }}
                />
                <Table.Column
                  title="Date of created"
                  dataIndex="createdAt"
                  key="createdAt"
                  render={(value: number, record: ArticleData) => {
                    if (typeof record?.createdAt === 'number') {
                      return format(new Date(record.createdAt), 'dd-MM-yyyy HH:mm')
                    }
                    return value
                  }}
                />
                <Table.Column
                  title="Date of last edition"
                  dataIndex="updatedAt"
                  key="updatedAt"
                  render={(value: number, record: CreateArticleOptions) => {
                    if (typeof record?.updatedAt === 'number') {
                      return format(new Date(record.updatedAt), 'dd-MM-yyyy HH:mm')
                    }
                    return value
                  }}
                />

                <Table.Column
                  title="Action"
                  dataIndex="action"
                  key="action"
                  render={(_id: any, record: ArticleData) => (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <Space>
                        <Button>
                          <Link to={generatePath(ROUTE.ADMIN_BLOG_ARTICLES_EDIT, { id: record.objectID })}>
                            <EditOutlined />
                          </Link>
                        </Button>
                        <Popconfirm
                          title="Are you sure?"
                          onConfirm={() => handleRemovePress(record.objectID)}
                          okText="Remove"
                          cancelText="Cancel"
                          okButtonProps={{ danger: true }}
                        >
                          <Button danger>
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </Space>
                    </div>
                  )}
                />
              </Table>
            )}
          </Col>
        </Row>
      </Space>
    </AuthorizedLayout>
  )
}

export default AdminBlogArticlesListRoute
