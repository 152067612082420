import React, { useEffect, useState } from 'react'
import { Badge, Breadcrumb, Button, Col, Divider, Popconfirm, Row, Space, Table, Tag, Typography } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { SorterResult, Key } from 'antd/lib/table/interface'
import { TablePaginationConfig } from 'antd/lib/table'
import { generatePath, Link } from 'react-router-dom'
import { format } from 'date-fns'

import { ExerciseData } from 'services/Exercises/types'
import useEventHandler from 'hooks/useEventHandler'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'
import { ROUTE } from 'routers/types'

import { AuthorizedLayout } from 'components/AuthorizedLayout'

const AdminExercisesRoute = () => {
  const { exercises } = useServices()

  const [data, setData] = useState<ExerciseData[]>([])
  const [isLoading, setLoading] = useState(true)

  const pageSize = 20
  const [page, setPage] = useState(1)
  const [currentSort, setCurrentSort] = useState<{ field: keyof ExerciseData; order: 'ascend' | 'descend' }>()

  useEffect(() => {
    const subscriber = exercises
      .getList(pageSize, pageSize * page - pageSize, currentSort?.field, currentSort?.order)
      .pipe(toResult())
      .subscribe(state => {
        setLoading(state.status === 'loading')
        if (state.status === 'success') {
          setData(state.payload.data)
        }
      })

    return () => subscriber.unsubscribe()
  }, [exercises, page, currentSort])

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filter: Record<string, (Key | boolean)[] | null>,
    sort: SorterResult<ExerciseData>
  ) => {
    if (sort.field !== currentSort?.field || sort.order !== currentSort?.order) {
      setPage(1)
    }
    // @ts-ignore
    setCurrentSort({ field: sort.field, order: sort.order })
  }

  const handleRemovePress = useEventHandler(
    (objectID: string) => {
      return exercises
        .delete(objectID)
        .pipe(toResult())
        .subscribe(state => {
          if (state.status === 'success') {
            setData(data.filter(item => item.objectID !== objectID))
          }
        })
    },
    [data]
  )

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Exercises</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <Space direction="vertical">
        <Row>
          <Col xs={{ span: 20, offset: 2 }}>
            <Table
              pagination={false}
              // @ts-ignore
              onChange={handleTableChange}
              rowKey="objectID"
              loading={isLoading}
              dataSource={data}
            >
              <Table.Column
                sorter={true}
                dataIndex="name"
                title="Name"
                render={(value: string, record: ExerciseData) => (
                  <Space direction="vertical">
                    <Typography.Text strong>{value}</Typography.Text>
                  </Space>
                )}
              />
              <Table.Column sorter={true} dataIndex="force" title="Force" />
              <Table.Column sorter={true} dataIndex="category" title="Category" />
              <Table.Column
                sorter={true}
                dataIndex="createdAt"
                title="Created Date"
                render={(value: string) => format(new Date(value), 'dd MMM yyyy')}
              />
              <Table.Column
                sorter={true}
                dataIndex="updatedAt"
                title="Updated Date"
                render={(value?: string) => (value ? format(new Date(value), 'dd MMM yyyy') : null)}
              />
              <Table.Column
                sorter={true}
                dataIndex="isPublic"
                title="Public"
                render={(value: boolean) =>
                  value ? (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <Badge status="success" />
                    </div>
                  ) : null
                }
              />
              <Table.Column
                sorter={true}
                dataIndex="name"
                title="Name"
                render={(_value: any, record: ExerciseData) => (
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Space>
                      <Button>
                        <Link to={generatePath(ROUTE.ADMIN_EXERCISES_EDIT, { id: record.objectID })}>
                          <EditOutlined />
                        </Link>
                      </Button>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleRemovePress(record.objectID)}
                        okText="Remove"
                        cancelText="Cancel"
                        okButtonProps={{ danger: true }}
                      >
                        <Button danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Space>
                  </div>
                )}
              />
            </Table>
          </Col>
        </Row>
        <Row justify="end" style={{ marginBottom: 48 }}>
          <Col xs={{ pull: 2 }}>
            <Space>
              <Button disabled={page === 1} onClick={() => page > 1 && setPage(page - 1)}>
                Prev
              </Button>
              <Badge count={page} />
              <Button onClick={() => setPage(page + 1)}>Next</Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </AuthorizedLayout>
  )
}

export default AdminExercisesRoute
