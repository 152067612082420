import React from 'react'
import { Badge, Button, Card, List, Typography } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { format, formatDistanceToNow } from 'date-fns'
import { generatePath, Link } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'

import { FoodProductData } from 'services/FoodProducts/types'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'
import { ROUTE } from 'routers/types'
import useEventHandler from 'hooks/useEventHandler'

const ProductsReportsList = () => {
  const { foodProducts } = useServices()

  const [toVerifyLoading, setToVerifyLoading] = React.useState(true)
  const [toVerifyData, setToVerifyData] = React.useState<FoodProductData[]>([])

  React.useEffect(() => {
    const productsToVerifySubscriber = foodProducts
      .getList(10, 0, 'createdAt', 'descend', true)
      .pipe(toResult())
      .subscribe(state => {
        setToVerifyLoading(state.status === 'loading')
        if (state.status === 'success') {
          setToVerifyData(state.payload.data.filter((d: FoodProductData) => d.toVerify))
        }
      })

    return () => productsToVerifySubscriber.unsubscribe()
  }, [foodProducts])

  const handleRefreshPress = useEventHandler(() => {
    return foodProducts
      .getList(10, 0, 'createdAt', 'descend', true, true)
      .pipe(toResult())
      .subscribe(state => {
        setToVerifyLoading(state.status === 'loading')
        if (state.status === 'success') {
          setToVerifyData(state.payload.data.filter((d: FoodProductData) => d.toVerify))
        }
      })
  })

  return (
    <Card
      title="Food Products to Verify"
      extra={
        <Button onClick={handleRefreshPress}>
          <ReloadOutlined />
        </Button>
      }
    >
      <List
        dataSource={toVerifyData}
        loading={toVerifyLoading}
        renderItem={item => (
          <List.Item
            actions={[
              <Button>
                <Link to={generatePath(ROUTE.ADMIN_FOOD_PRODUCTS_EDIT, { id: item.objectID })}>
                  <EditOutlined />
                </Link>
              </Button>
            ]}
          >
            <List.Item.Meta
              title={
                <>
                  <Badge status={item.isPublic ? 'success' : 'processing'} />{' '}
                  <Typography.Text strong>{item.name}</Typography.Text>
                </>
              }
              description={`${format(new Date(item.createdAt), 'dd MMMM yyyy')} - ${formatDistanceToNow(
                new Date(item.createdAt)
              )}`}
            />
          </List.Item>
        )}
      />
    </Card>
  )
}

export default ProductsReportsList
