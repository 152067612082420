import { CurrentUser } from 'services/types'

export type RoleType = 'Admin' | 'ExercisesModerator'
export const Role: Record<RoleType, RoleType> = {
  Admin: 'Admin',
  ExercisesModerator: 'ExercisesModerator'
}

export const getRole = (user: CurrentUser) => {
  if (user.isAdmin) {
    return Role.Admin
  }

  if (user.isExercisesModerator) {
    return Role.ExercisesModerator
  }

  return null
}

export const isAvailable = (user: CurrentUser | null) => (roles?: RoleType[]) => {
  if (!user || !roles) {
    return null
  }

  const userRole = getRole(user)
  if (!userRole) {
    return null
  }

  return roles.includes(userRole)
}
