import { FoodProductData } from 'services/FoodProducts/types'

type GetMacrosByQuantityOuput = Pick<
  FoodProductData,
  'energyValue' | 'fats' | 'carbohydrates' | 'proteins' | 'fiber' | 'sugar' | 'salt'
>

export const getMacrosByQuantity = (quantity: number, product: FoodProductData): GetMacrosByQuantityOuput => {
  const ratio = 100 / quantity

  return {
    energyValue: Number((product.energyValue / ratio).toFixed(3)),
    proteins: Number((product.proteins / ratio).toFixed(3)),
    fats: Number((product.fats / ratio).toFixed(3)),
    carbohydrates: Number((product.carbohydrates / ratio).toFixed(3)),
    fiber: product.fiber ? Number((product.fiber / ratio).toFixed(3)) : undefined,
    salt: product.salt ? Number((product.salt / ratio).toFixed(3)) : undefined,
    sugar: product.sugar ? Number((product.sugar / ratio).toFixed(3)) : undefined
  }
}
