import React from "react";
import { Redirect } from "react-router-dom";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ROUTE } from "routers/types";
import { Layout } from "antd";

interface Props {
  children: any;
}

export const AuthorizedLayout = (props: Props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentUser, isLoaded } = useCurrentUser();
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Redirect to={{ pathname: ROUTE.ADMIN_LOGIN }} />;
  }

  return <Layout>{props.children}</Layout>;
};
