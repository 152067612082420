import { useEffect, useState } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { Breadcrumb, Form, Col, Divider, Row } from 'antd'

import styled from 'styled-components'

import { ArticleData, CreateArticleOptions } from 'services/Articles/types'
import { AuthorizedLayout } from 'components/AuthorizedLayout'
import { FormAddEdit } from './components/addEditForm'
import { Spinner } from './components/spinners'
import useServices from 'hooks/useServices'
import { ROUTE } from 'routers/types'

const StyledRow = styled(Row)`
  .ant-form-item-control {
    max-width: 700px;
  }

  .ant-form-item-label {
    label {
      margin: auto;
      font-weight: 600;
    }
  }
`

const AdminBlogArticlesEditRoute = () => {
  const { id } = useParams<{ id?: string }>()

  const { articles } = useServices()
  const [form] = Form.useForm<CreateArticleOptions>()

  const [articleData, setArticleData] = useState<ArticleData>()
  const [isLoading, setLoading] = useState(true)
  const [redirectToList, setRedirectToList] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }
    articles.getOne(id).then(oneArticle => {
      if (!oneArticle) {
        // throw new Error('Invalid article data')
      }
      setLoading(false)
      setArticleData(oneArticle)
    })
  }, [])

  useEffect(() => {
    if (!articleData) {
      return
    }

    form.setFieldsValue({ authorName: articleData?.author.name || '' })
    form.setFieldsValue({ authorSurname: articleData?.author.surname || '' })
    form.setFieldsValue({ authorAvatar: articleData?.author.avatar || '' })
    form.setFieldsValue({ authorShortDescription: articleData?.author.shortDescription || '' })
    form.setFieldsValue({ authorLongDescription: articleData?.author.longDescription || '' })
    form.setFieldsValue({ image: articleData?.image || '' })
    form.setFieldsValue({ lang: articleData?.lang || '' })
    form.setFieldsValue({ promo: articleData?.promo })
    form.setFieldsValue({ section: articleData?.section })
    form.setFieldsValue({ slug: articleData?.slug || '' })
    form.setFieldsValue({ title: articleData?.title || '' })
    form.setFieldsValue({ text: articleData?.text || '' })
    form.setFieldsValue({ tags: articleData?.tags || '' })
    form.setFieldsValue({ authorAvatar: articleData?.author.avatar || '' })
    form.setFieldsValue({ authorAvatar: articleData?.author.avatar || '' })
    form.setFieldsValue({ authorAvatar: articleData?.author.avatar || '' })
  }, [articleData, form])

  const onFinish = (values: CreateArticleOptions) => {
    if (id === undefined) {
      return
    }
    articles
      .update(id, values)
      .then(editedArticleStatus => {
        if (!editedArticleStatus) {
          throw new Error('Invalid article data')
        }
        setRedirectToList(true)
      })
      .catch((err: string) => {
        return alert(err)
      })
  }
  if (redirectToList) {
    return <Redirect to={ROUTE.ADMIN_BLOG_ARTICLES_LIST} />
  }

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }} xl={{ span: 24, offset: 1 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN_BLOG_ARTICLES_LIST}>Blog Articles</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Edit Article</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      {isLoading ? (
        <Spinner isSpinning={true} />
      ) : (
        <StyledRow>
          <Col xs={{ span: 20, offset: 2 }} xl={{ span: 24, offset: 1 }}>
            <FormAddEdit form={form} onFinish={onFinish} /*onFinish={onFinish}*/ />
          </Col>
        </StyledRow>
      )}
    </AuthorizedLayout>
  )
}

export default AdminBlogArticlesEditRoute
