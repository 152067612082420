import React, { useEffect, useState } from 'react'
import { Badge, Breadcrumb, Button, Col, Divider, Popconfirm, Row, Space, Switch, Table, Typography } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { SorterResult, Key } from 'antd/lib/table/interface'
import { TablePaginationConfig } from 'antd/lib/table'
import { generatePath, Link } from 'react-router-dom'
import { format } from 'date-fns'

import { FoodProductData } from 'services/FoodProducts/types'
import useEventHandler from 'hooks/useEventHandler'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'
import { ROUTE } from 'routers/types'

import { AuthorizedLayout } from 'components/AuthorizedLayout'

const AdminFoodProductsToVerifyRoute = () => {
  const { foodProducts } = useServices()

  const [data, setData] = useState<FoodProductData[]>([])
  const [isLoading, setLoading] = useState(true)

  const pageSize = 50
  const [page, setPage] = useState(1)
  const [currentSort, setCurrentSort] = useState<{ field: keyof FoodProductData; order: 'ascend' | 'descend' }>()

  useEffect(() => {
    const subscriber = foodProducts
      .getList(pageSize, pageSize * page - pageSize, currentSort?.field, currentSort?.order, true)
      .pipe(toResult())
      .subscribe(state => {
        setLoading(state.status === 'loading')
        if (state.status === 'success') {
          setData(state.payload.data)
        }
      })

    return () => subscriber.unsubscribe()
  }, [foodProducts, page, currentSort])

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filter: Record<string, (Key | boolean)[] | null>,
    sort: SorterResult<FoodProductData>
  ) => {
    if (sort.field !== currentSort?.field || sort.order !== currentSort?.order) {
      setPage(1)
    }
    // @ts-ignore
    setCurrentSort({ field: sort.field, order: sort.order })
  }

  const handleRemovePress = useEventHandler(
    (objectID: string) => {
      return foodProducts
        .delete(objectID)
        .pipe(toResult())
        .subscribe(state => {
          if (state.status === 'success') {
            setData(data.filter(item => item.objectID !== objectID))
          }
        })
    },
    [data]
  )

  const handleSwitchPublic = useEventHandler((object: FoodProductData, changeTo: boolean) => {
    return foodProducts
      .update(object.objectID, { ...object, isPublic: changeTo })
      .pipe(toResult())
      .subscribe(state => {
        if (state.status === 'error') {
          alert('Failed, some error on server side')
        }
      })
  }, [])

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Food products</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <Space direction="vertical">
        <Row>
          <Col xs={{ span: 20, offset: 2 }}>
            <Table
              pagination={false}
              // @ts-ignore
              onChange={handleTableChange}
              rowKey="objectID"
              loading={isLoading}
              dataSource={data}
            >
              <Table.Column
                sorter={true}
                dataIndex="name"
                title="Name"
                render={(value: string) => (
                  <Space direction="vertical">
                    <Typography.Text strong>{value}</Typography.Text>
                  </Space>
                )}
              />
              <Table.Column
                sorter={true}
                dataIndex="brand"
                title="Brand / EAN"
                render={(value: string, record: FoodProductData) => (
                  <Space direction="vertical">
                    <Typography.Text>{value}</Typography.Text>
                    <Typography.Text>{record.barcode}</Typography.Text>
                  </Space>
                )}
              />
              <Table.Column
                sorter={true}
                dataIndex="energyValue"
                title="Nutritions"
                render={(value: string, record: FoodProductData) => (
                  <Space direction="vertical" size="small">
                    <Typography.Text>{value} kcal</Typography.Text>
                    <Typography.Text>
                      {record.proteins} / {record.fats} / {record.carbohydrates}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column
                sorter={true}
                dataIndex="createdAt"
                title="Created Date"
                render={(value: string) => format(new Date(value), 'dd MMM yyyy')}
              />
              <Table.Column
                sorter={true}
                dataIndex="updatedAt"
                title="Updated Date"
                render={(value?: string) => (value ? format(new Date(value), 'dd MMM yyyy') : null)}
              />
              <Table.Column
                sorter={true}
                dataIndex="isPublic"
                title="Public"
                render={(value: boolean, record: FoodProductData) => (
                  <Switch defaultChecked={value} onChange={checked => handleSwitchPublic(record, checked)} />
                )}
              />
              <Table.Column
                // sorter={true}
                dataIndex="actions"
                title="Actions"
                render={(_value: any, record: FoodProductData) => (
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Space>
                      <Button>
                        <Link to={generatePath(ROUTE.ADMIN_FOOD_PRODUCTS_EDIT, { id: record.objectID })}>
                          <EditOutlined />
                        </Link>
                      </Button>

                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleRemovePress(record.objectID)}
                        okText="Remove"
                        cancelText="Cancel"
                        okButtonProps={{ danger: true }}
                      >
                        <Button danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Space>
                  </div>
                )}
              />
            </Table>
          </Col>
        </Row>
        <Row justify="end" style={{ marginBottom: 48 }}>
          <Col xs={{ pull: 2 }}>
            <Space>
              <Button disabled={page === 1} onClick={() => page > 1 && setPage(page - 1)}>
                Prev
              </Button>
              <Badge count={page} />
              <Button disabled={data.length < pageSize} onClick={() => setPage(page + 1)}>
                Next
              </Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </AuthorizedLayout>
  )
}

export default AdminFoodProductsToVerifyRoute
