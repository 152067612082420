import { BehaviorSubject, from } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import Api from 'services/Api'
import { CurrentUser } from 'services/types'

class Auth {
  private api: Api

  user: BehaviorSubject<CurrentUser | null>

  constructor(api: Api) {
    this.api = api
    this.user = new BehaviorSubject<CurrentUser | null>(null)

    const data = localStorage.getItem('_user')
    if (data && api.token) {
      this.user.next(JSON.parse(data).user)
    }
  }

  private setUser(user: CurrentUser) {
    localStorage.setItem('_user', JSON.stringify({ user, date: Date.now() }))
    this.user.next(user)
  }

  login(email: string, password: string) {
    const url = `${this.api.url}/auth/login`
    return from(
      fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          email,
          password
        })
      })
    ).pipe(
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        if (!response.token) {
          throw new Error('No token in response')
        }

        if (!response.user) {
          throw new Error('No user in response')
        }

        this.api.setToken(response.token)
        this.setUser(response.user)

        return response
      })
    )
  }
}

export default Auth
