import { ArticleData, ArticleDataRaw, CreateArticleOptions, UpdatedArticleDataRaw } from './types'
import { stringToSlug, validateArticleChanges } from './utils'

const requestURL = 'https://articles-4c2e.restdb.io/rest/articles'

class Articles {
  getList = (): Promise<ArticleData[]> => {
    return fetch(requestURL, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '60901ca0f2fc22523a42c79c'
      }
    }).then(async function (response) {
      let articles: ArticleData[]
      articles = await response.json()

      return articles.map(article => {
        return {
          ...article,
          // @ts-ignore
          objectID: article._id
        }
      })
    })
  }

  create = (values: CreateArticleOptions): Promise<boolean> => {
    try {
      validateArticleChanges(values)

      const articlesData: ArticleDataRaw = {
        author: {
          name: values.authorName,
          surname: values.authorSurname,
          avatar: values.authorAvatar,
          shortDescription: values.authorShortDescription,
          longDescription: values.authorLongDescription
        },
        image: values.image,
        createdAt: Date.now(),
        createdBy: values.authorName,

        lang: values.lang,
        promo: values.promo,
        section: values.section,
        slug: stringToSlug(values.slug || values.title),
        title: values.title,
        text: values.text,
        tags: values.tags
      }

      return fetch(requestURL, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '60901ca0f2fc22523a42c79c'
        },

        body: JSON.stringify(articlesData)
      })
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  delete = (id: string) => {
    const requestURL = `https://articles-4c2e.restdb.io/rest/articles/${id}`

    return fetch(requestURL, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '60901ca0f2fc22523a42c79c'
      }
    }).then(function () {
      return true
    })
  }

  getOne = (id: string) => {
    const requestURL = `https://articles-4c2e.restdb.io/rest/articles/${id}`
    return fetch(requestURL, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '60901ca0f2fc22523a42c79c'
      }
    }).then(async function (response) {
      let oneArticle: ArticleData
      oneArticle = await response.json()
      return oneArticle
    })
  }

  update = (id: string, values: CreateArticleOptions): Promise<boolean> => {
    const requestURL = `https://articles-4c2e.restdb.io/rest/articles/${id}`

    try {
      validateArticleChanges(values)

      const articlesData: UpdatedArticleDataRaw = {
        author: {
          name: values.authorName,
          surname: values.authorSurname,
          avatar: values.authorAvatar,
          shortDescription: values.authorShortDescription,
          longDescription: values.authorLongDescription
        },
        image: values.image,
        createdAt: values.createdAt,
        createdBy: values.createdBy,
        updatedAt: Date.now(),
        updatedBy: values.authorName,

        lang: values.lang,
        promo: values.promo,
        section: values.section,
        slug: stringToSlug(values.slug || values.title),
        title: values.title,
        text: values.text,
        tags: values.tags
      }

      return fetch(requestURL, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '60901ca0f2fc22523a42c79c'
        },

        body: JSON.stringify(articlesData)
      })
        .then(() => {
          console.log('new', articlesData)
          return true
        })
        .catch(() => {
          return false
        })
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default Articles
