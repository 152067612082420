import React, { useState } from 'react'
import { Form, Input, Button, Layout, Col, Row, Alert, Spin } from 'antd'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ROUTE } from 'routers/types'
import useServices from 'hooks/useServices'
import useEventHandler from 'hooks/useEventHandler'
import { toResult } from 'services/utils'

const layout = {
  labelCol: { xs: { span: 6 }, md: { span: 4 } },
  wrapperCol: { xs: { span: 12 }, md: { span: 16 } }
}
const tailLayout = {
  wrapperCol: { xs: { offset: 0, span: 24 }, md: { offset: 4, span: 20 } }
}

const StylesLayout = styled(Layout)`
  height: 100vh;
  justify-content: center;

  .alertContainer {
    position: relative;
    justify-content: center;
    display: flex;
    .ant-alert {
      top: -80px;
      position: absolute;
    }
  }

  form.ant-form {
    > .ant-row {
      @media (max-width: 768px) {
        .ant-form-item-label {
          padding-bottom: 0;
        }
      }
      @media (min-width: 768px) {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`

const AdminLoginRoute = () => {
  const history = useHistory()
  const { auth } = useServices()

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const onFinish = useEventHandler((values: { email: string; password: string }) => {
    setError(undefined)
    return auth
      .login(values.email, values.password)
      .pipe(toResult())
      .subscribe(state => {
        setLoading(state.status === 'loading')
        if (state.status === 'success') {
          console.log(state)
          history.replace(ROUTE.ADMIN)
        }

        if (state.status === 'error') {
          setError(state.payload.toString())
        }
      })
  }, [])

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <StylesLayout className="vertical-center">
      <Row>
        <Col xs={{ span: 20, offset: 2 }}>
          {error ? (
            <Col xs={{ span: 20, offset: 0 }} md={{ span: 16, offset: 4 }} className="alertContainer">
              <Alert message={error} type="error" showIcon />
            </Col>
          ) : null}

          <Form
            {...layout}
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your username!' }]}>
              <Input className="" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              {isLoading ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </StylesLayout>
  )
}

export default AdminLoginRoute
