import React from 'react'

import 'antd/dist/antd.css'
import './App.css'

import { makeServices, Provider as ServicesProvider } from './services'
import Routes from './routers'

const services = makeServices()

const App = () => {
  return (
    <ServicesProvider value={services}>
      <Routes />
    </ServicesProvider>
  )
}

export default App
