import React, { useState } from 'react'
import { Breadcrumb, Button, Col, Divider, Form, message, Row, Space } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { ROUTE } from 'routers/types'

import { ExerciseData } from 'services/Exercises/types'
import useEventHandler from 'hooks/useEventHandler'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'

import { AuthorizedLayout } from 'components/AuthorizedLayout'
import ExerciseForm from './form'

const StyledRow = styled(Row)`
  .ant-form-item-control {
    max-width: 400px;
  }

  .ant-form-item-label {
    label {
      margin-right: 12px;
      font-weight: 600;
    }
  }
`

const AdminExercisesAddRoute = () => {
  const { exercises: exercisesService } = useServices()
  const [form] = Form.useForm<ExerciseData>()

  const [isUpdating, setUpdating] = useState(false)
  const [navigateToNew, setNavigateToNew] = useState(false)
  const [isCreated, setCreated] = useState(false)

  const handleFinish = useEventHandler(
    (values: ExerciseData) => {
      return exercisesService
        .create({ ...values })
        .pipe(toResult())
        .subscribe(state => {
          setUpdating(state.status === 'loading')
          if (state.status === 'success') {
            message.success(`Exercises ${values.name} has been created`)
            setCreated(true)
          }
        })
    },
    [exercisesService, navigateToNew, form]
  )

  if (isCreated) {
    if (!navigateToNew) {
      return <Redirect to={ROUTE.ADMIN_EXERCISES} />
    } else {
      form.resetFields()
    }
  }

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN_EXERCISES}>Exercises</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Add new</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <StyledRow>
        <Col xs={{ span: 20, offset: 2 }}>
          <ExerciseForm form={form} onFinish={handleFinish} isUpdating={isUpdating}>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setNavigateToNew(false)}
                size="large"
                icon={<PlusCircleFilled />}
              >
                Save
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setNavigateToNew(true)}
                size="large"
                icon={<PlusCircleFilled />}
              >
                Save and create new
              </Button>
            </Space>
          </ExerciseForm>
        </Col>
      </StyledRow>
    </AuthorizedLayout>
  )
}

export default AdminExercisesAddRoute
