import React from 'react'
import AdminDashboardRoute from './Admin/Dashboard'
import AdminExercisesRoute from './Admin/Exercises'
import AdminExercisesPublicListRoute from './Admin/Exercises/public'
import AdminExercisesAddRoute from './Admin/Exercises/add'
import AdminExercisesEditRoute from './Admin/Exercises/edit'
import AdminFoodProductsRoute from './Admin/FoodProducts'
import AdminFoodProductsToVerifyRoute from './Admin/FoodProducts/toVerify'
import AdminFoodProductsEditRoute from './Admin/FoodProducts/edit'
import AdminFoodProductsJsonRoute from './Admin/FoodProductsJson'
import AdminLoginRoute from './Admin/Login'
import AdminBlogArticlesListRoute from './Admin/Articles/index'
import AdminBlogArticlesAddRoute from './Admin/Articles/add'
import AdminBlogArticlesEditRoute from './Admin/Articles/edit'
import { RoleType } from 'utils/roles'

export const ROUTE = {
  ADMIN_LOGIN: '/',
  ADMIN: '/admin',
  ADMIN_EXERCISES: '/admin/exercises',
  ADMIN_EXERCISES_PUBLIC: '/admin/exercises/public',
  ADMIN_EXERCISES_ADD: '/admin/exercises/add',
  ADMIN_EXERCISES_EDIT: '/admin/exercises/edit/:id',
  ADMIN_FOOD_PRODUCTS: '/admin/food-products',
  ADMIN_FOOD_PRODUCTS_TO_VERIFY: '/admin/food-products/to-verify',
  ADMIN_FOOD_PRODUCTS_ADD: '/admin/food-products/add',
  ADMIN_FOOD_PRODUCTS_EDIT: '/admin/food-products/edit/:id',
  ADMIN_FOOD_PRODUCTS_JSON: '/admin/food-products/json',
  ADMIN_BLOG_ARTICLES_LIST: '/admin/blog-articles',
  ADMIN_BLOG_ARTICLES_ADD: '/admin/blog-articles-add',
  ADMIN_BLOG_ARTICLES_EDIT: '/admin/blog-articles-edit/:id'
}

const createRoute = (
  path: string,
  component: React.FunctionComponent,
  exact: boolean = false,
  key: string,
  roles?: RoleType[],
  type?: string
) => ({
  component,
  exact,
  key,
  path,
  type,
  roles
})

export const unauthorizedRoutes = [
  createRoute(ROUTE.ADMIN, AdminDashboardRoute, true, 'ADMIN'),
  createRoute(ROUTE.ADMIN_LOGIN, AdminLoginRoute, true, 'ADMIN_LOGIN')
]

export const authorizedRoutes = [
  createRoute(ROUTE.ADMIN_EXERCISES, AdminExercisesRoute, true, 'ADMIN_EXERCISES', ['Admin', 'ExercisesModerator']),
  createRoute(ROUTE.ADMIN_EXERCISES_PUBLIC, AdminExercisesPublicListRoute, true, 'ADMIN_EXERCISES_PUBLIC', [
    'Admin',
    'ExercisesModerator'
  ]),
  createRoute(ROUTE.ADMIN_EXERCISES_ADD, AdminExercisesAddRoute, true, 'ADMIN_EXERCISES_ADD', [
    'Admin',
    'ExercisesModerator'
  ]),
  createRoute(ROUTE.ADMIN_EXERCISES_EDIT, AdminExercisesEditRoute, true, 'ADMIN_EXERCISES_EDIT', [
    'Admin',
    'ExercisesModerator'
  ]),
  createRoute(ROUTE.ADMIN_FOOD_PRODUCTS, AdminFoodProductsRoute, true, 'ADMIN_FOOD_PRODUCTS', ['Admin']),
  createRoute(
    ROUTE.ADMIN_FOOD_PRODUCTS_TO_VERIFY,
    AdminFoodProductsToVerifyRoute,
    true,
    'ADMIN_FOOD_PRODUCTS_TO_VERIFY',
    ['Admin']
  ),
  // createRoute(ROUTE.ADMIN_FOOD_PRODUCTS_ADD, AdminFoodProductsAddRoute, true, 'ADMIN_FOOD_PRODUCTS_ADD', ['Admin']),
  createRoute(ROUTE.ADMIN_FOOD_PRODUCTS_EDIT, AdminFoodProductsEditRoute, true, 'ADMIN_FOOD_PRODUCTS_EDIT', ['Admin']),
  createRoute(ROUTE.ADMIN_FOOD_PRODUCTS_JSON, AdminFoodProductsJsonRoute, true, 'ADMIN_FOOD_PRODUCTS_JSON', ['Admin']),
  createRoute(ROUTE.ADMIN_BLOG_ARTICLES_LIST, AdminBlogArticlesListRoute, true, 'ADMIN_BLOG_ARTICLES_LIST', ['Admin']),
  createRoute(ROUTE.ADMIN_BLOG_ARTICLES_ADD, AdminBlogArticlesAddRoute, true, 'ADMIN_BLOG_ARTICLES_ADD', ['Admin']),
  createRoute(ROUTE.ADMIN_BLOG_ARTICLES_EDIT, AdminBlogArticlesEditRoute, true, 'ADMIN_BLOG_ARTICLES_EDIT', ['Admin'])
]
