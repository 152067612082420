import React from 'react'
import { Layout, Menu as AntMenu, Typography } from 'antd'
import { HomeFilled, UnorderedListOutlined, EditFilled, PlusCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ROUTE } from 'routers/types'
import useServices from 'hooks/useServices'
import { useObservable } from 'rxjs-hooks'

const StyledSider = styled(Layout.Sider)`
  min-height: 100vh;
  background-color: #141414;

  h1 {
    text-align: center;
    margin: 24px 0;
    color: white;
  }
`

interface Props {
  currentActive?: string
  width?: number
}

const Menu = (props: Props) => {
  const { width = 260 } = props
  const location = useLocation()
  const { auth } = useServices()
  const user = useObservable(() => auth.user)

  const subMenuKeys = {
    [ROUTE.ADMIN_EXERCISES]: 'exercises',
    [ROUTE.ADMIN_EXERCISES_ADD]: 'exercises'
  }

  const canSeeExercises = user?.isAdmin || user?.isExercisesModerator
  const canSeeFoodProducts = user?.isAdmin
  const canSeeBlogArticles = user?.isAdmin

  return (
    <StyledSider width={width}>
      <Typography.Title level={1}>FitMate</Typography.Title>
      <AntMenu
        title="Basic Drawer"
        style={{ width }}
        mode="inline"
        defaultOpenKeys={subMenuKeys[location.pathname] ? [subMenuKeys[location.pathname]] : undefined}
        selectedKeys={[location.pathname]}
      >
        <AntMenu.Item key={ROUTE.ADMIN} icon={<HomeFilled />}>
          <Link to={ROUTE.ADMIN}>Home</Link>
        </AntMenu.Item>
        {canSeeExercises ? (
          <AntMenu.SubMenu key="exercises" title="Exercises" icon={<EditFilled />}>
            <AntMenu.Item key={ROUTE.ADMIN_EXERCISES} icon={<UnorderedListOutlined />}>
              <Link to={ROUTE.ADMIN_EXERCISES}>List (all)</Link>
            </AntMenu.Item>
            <AntMenu.Item key={ROUTE.ADMIN_EXERCISES_PUBLIC} icon={<UnorderedListOutlined />}>
              <Link to={ROUTE.ADMIN_EXERCISES_PUBLIC}>List (public)</Link>
            </AntMenu.Item>
            <AntMenu.Item key={ROUTE.ADMIN_EXERCISES_ADD} icon={<PlusCircleFilled />}>
              <Link to={ROUTE.ADMIN_EXERCISES_ADD}>Add new</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
        ) : null}
        {canSeeFoodProducts ? (
          <AntMenu.SubMenu key="food_products" title="Food Products (Users)" icon={<EditFilled />}>
            <AntMenu.Item key={ROUTE.ADMIN_FOOD_PRODUCTS} icon={<UnorderedListOutlined />}>
              <Link to={ROUTE.ADMIN_FOOD_PRODUCTS}>List</Link>
            </AntMenu.Item>
            <AntMenu.Item key={ROUTE.ADMIN_FOOD_PRODUCTS_TO_VERIFY} icon={<UnorderedListOutlined />}>
              <Link to={ROUTE.ADMIN_FOOD_PRODUCTS_TO_VERIFY}>To verify</Link>
            </AntMenu.Item>
            {/* <AntMenu.Item key={ROUTE.ADMIN_FOOD_PRODUCTS_ADD} icon={<PlusCircleFilled />}>
            <Link to={ROUTE.ADMIN_FOOD_PRODUCTS_ADD}>Add new</Link>
          </AntMenu.Item> */}
          </AntMenu.SubMenu>
        ) : null}
        {canSeeFoodProducts ? (
          <AntMenu.SubMenu key="food_products_json" title="Food Products (JSON)" icon={<EditFilled />}>
            <AntMenu.Item key={ROUTE.ADMIN_FOOD_PRODUCTS_JSON} icon={<UnorderedListOutlined />}>
              <Link to={ROUTE.ADMIN_FOOD_PRODUCTS_JSON}>List</Link>
            </AntMenu.Item>
            {/* <AntMenu.Item key={ROUTE.ADMIN_FOOD_PRODUCTS_TO_VERIFY} icon={<UnorderedListOutlined />}>
            <Link to={ROUTE.ADMIN_FOOD_PRODUCTS_TO_VERIFY}>To verify</Link>
          </AntMenu.Item> */}
            {/* <AntMenu.Item key={ROUTE.ADMIN_FOOD_PRODUCTS_ADD} icon={<PlusCircleFilled />}>
            <Link to={ROUTE.ADMIN_FOOD_PRODUCTS_ADD}>Add new</Link>
          </AntMenu.Item> */}
          </AntMenu.SubMenu>
        ) : null}
        {canSeeBlogArticles ? (
          <AntMenu.SubMenu key="blog_articles" title="Blog Articles" icon={<EditFilled />}>
            <AntMenu.Item key={ROUTE.ADMIN_BLOG_ARTICLES_LIST} icon={<UnorderedListOutlined />}>
              <Link to={ROUTE.ADMIN_BLOG_ARTICLES_LIST}>List</Link>
            </AntMenu.Item>
            <AntMenu.Item key={ROUTE.ADMIN_BLOG_ARTICLES_ADD} icon={<PlusCircleFilled />}>
              <Link to={ROUTE.ADMIN_BLOG_ARTICLES_ADD}>Add new</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
        ) : null}
      </AntMenu>
    </StyledSider>
  )
}

export default Menu
