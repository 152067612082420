class Api {
  url: string
  token: string | null

  constructor() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error('REACT_APP_API_URL is undefined')
    }

    this.token = localStorage.getItem('_token')
    this.url = process.env.REACT_APP_API_URL
  }

  setToken(token: string) {
    localStorage.setItem('_token', token)
    this.token = token
  }

  fetchAuth(url: string, options: RequestInit) {
    const headers = new Headers({
      ...options.headers
    })

    if (this.token) {
      headers.set('authorization', `Bearer ${this.token}`)
    }

    const fetchUrl = `${this.url}/${url}`.replace(/([0-9a-zA-Z])(\/\/)/g, '$1/')
    return fetch(fetchUrl, {
      ...options,
      headers
    })
  }
}

export default Api
