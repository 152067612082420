import { Row, Spin } from 'antd'

interface Props {
  isSpinning: boolean
}

export const Spinner = (props: Props) => {
  const { isSpinning } = props
  return (
    <Row align="middle" justify="center">
      <Spin size="large" spinning={isSpinning} />
    </Row>
  )
}
