import { ROUTE } from 'routers/types'
import { from } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import Api from 'services/Api'
import { FoodProductReportData } from './types'

class FoodProductsReports {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  getList(
    limit = 10,
    offset = 0,
    sort?: keyof FoodProductReportData,
    order: 'ascend' | 'descend' = 'ascend',
    showOnlyNonResolved?: boolean,
    forceCacheClear?: boolean
  ) {
    const searchParams = new URLSearchParams({ limit: limit.toString(), offset: offset.toString() })
    if (sort && order) {
      searchParams.append('sort', sort)
      searchParams.append('order', order)
    }

    showOnlyNonResolved && searchParams.append('non-resolved', '1')
    forceCacheClear && searchParams.append('no-cache', '1')

    const query = searchParams.toString()
    return from(this.api.fetchAuth(`/food-products-reports?${query}`, { method: 'GET' })).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  resolve(objectId: string, isResolved: boolean, resolveComment: string) {
    return from(
      this.api.fetchAuth(`/food-products-reports/resolve/${objectId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          isResolved,
          resolveComment
        })
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }
}

export default FoodProductsReports
