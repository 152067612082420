import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Divider, Form, message, Spin, Row, Button } from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ROUTE } from 'routers/types'

import { ExerciseData } from 'services/Exercises/types'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'

import { AuthorizedLayout } from 'components/AuthorizedLayout'
import useEventHandler from 'hooks/useEventHandler'
import { EMPTY } from 'rxjs'
import ExerciseForm from './form'

const StyledRow = styled(Row)`
  .ant-form-item-control {
    max-width: 400px;
  }

  .ant-form-item-label {
    label {
      margin-right: 12px;
      font-weight: 600;
    }
  }
`

const AdminExercisesEditRoute = () => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { exercises: exercisesService } = useServices()
  const [form] = Form.useForm<ExerciseData>()

  const [isLoading, setLoading] = useState(false)
  const [isUpdating, setUpdating] = useState(false)
  const [exercise, setExercise] = useState<ExerciseData>()

  useEffect(() => {
    if (!id) {
      return
    }

    const subscriber = exercisesService
      .getOne(id)
      .pipe(toResult())
      .subscribe(state => {
        setLoading(state.status === 'loading')
        if (state.status === 'success') {
          setExercise(state.payload.data)
        }
      })

    return () => subscriber.unsubscribe()
  }, [exercisesService, id])

  useEffect(() => {
    if (!exercise) {
      return
    }

    form.setFieldsValue({ locales: exercise.locales })
    form.setFieldsValue({ category: exercise.category })
    form.setFieldsValue({ force: exercise.force })
    form.setFieldsValue({ tags: exercise.tags })
    form.setFieldsValue({ isPublic: exercise.isPublic })
    form.setFieldsValue({ detailsUrl: exercise.detailsUrl })
  }, [exercise, form])

  const handleFinish = useEventHandler(
    (values: ExerciseData) => {
      console.log(values)
      if (!id || !exercise) {
        return EMPTY.subscribe()
      }

      return exercisesService
        .update(id, { ...exercise, ...values })
        .pipe(toResult())
        .subscribe(state => {
          setUpdating(state.status === 'loading')
          if (state.status === 'success') {
            message.success(`Exercises ${values.name} has been updated`)
            history.goBack()
          }
        })
    },
    [exercisesService, exercise, id]
  )

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN_EXERCISES}>Exercises</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Edit</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <StyledRow>
        {isLoading ? (
          <Col xs={{ span: 20, offset: 2 }}>
            <Spin size="large" />
          </Col>
        ) : (
          <Col xs={{ span: 24 }}>
            <ExerciseForm form={form} onFinish={handleFinish} isUpdating={isUpdating}>
              <Button type="primary" htmlType="submit" size="large">
                Update
              </Button>
            </ExerciseForm>
          </Col>
        )}
      </StyledRow>
    </AuthorizedLayout>
  )
}

export default AdminExercisesEditRoute
