import { Layout } from 'antd'
import Menu from 'components/Menu'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useEffect, useRef, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { isAvailable } from 'utils/roles'

import { unauthorizedRoutes, authorizedRoutes, ROUTE } from './types'

const Routes = () => {
  const { isLoaded, currentUser } = useCurrentUser()
  const isLogged = isLoaded && Boolean(currentUser)

  const routerRef = useRef<BrowserRouter>(null)

  const noFound = () => <Redirect to={ROUTE.ADMIN_LOGIN} />
  const isPathAvailable = isAvailable(currentUser)

  const [isMenuVisible, setMenuVisible] = useState<boolean>(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      // @ts-ignore
      setMenuVisible(isLogged && routerRef.current?.history?.location.pathname.includes('/admin'))
    }, 250)

    return () => clearTimeout(timeout)
  }, [isLogged, routerRef, currentUser])

  return (
    <BrowserRouter ref={routerRef}>
      <Layout>
        {isMenuVisible ? <Menu /> : null}

        <Switch>
          {unauthorizedRoutes.map(route => (
            <Route component={route.component} exact={route.exact} key={route.key} path={route.path} />
          ))}
          {isLogged
            ? authorizedRoutes
                .filter(route => isPathAvailable(route.roles))
                .map(route => (
                  <Route component={route.component} exact={route.exact} key={route.key} path={route.path} />
                ))
            : null}
          {isLoaded && !Boolean(currentUser) ? <Route component={noFound} /> : null}
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default Routes
