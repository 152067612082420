import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Spin,
  Switch,
  Space,
  Card,
  Popconfirm,
  Typography
} from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ROUTE } from 'routers/types'

import { FoodProductData } from 'services/FoodProducts/types'
import useServices from 'hooks/useServices'
import { toResult } from 'services/utils'

import { AuthorizedLayout } from 'components/AuthorizedLayout'
import useEventHandler from 'hooks/useEventHandler'
import { EMPTY } from 'rxjs'
import { getMacrosByQuantity } from './utils'
import { format } from 'date-fns'

const StyledRow = styled(Row)`
  .ant-form-item-control {
    max-width: 400px;
  }

  .ant-form-item-label {
    label {
      margin-right: 12px;
      font-weight: 600;
    }
  }
`

const AdminFoodProductsEditRoute = () => {
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { foodProducts: foodProductsService } = useServices()
  const [form] = Form.useForm<FoodProductData>()

  const [isLoading, setLoading] = useState(false)
  const [isUpdating, setUpdating] = useState(false)
  const [foodProduct, setFoodProduct] = useState<FoodProductData>()

  const [isPackage, setPackage] = useState(false)
  const [isServing, setServing] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }

    const subscriber = foodProductsService
      .getOne(id)
      .pipe(toResult())
      .subscribe(state => {
        console.log(state)
        setLoading(state.status === 'loading')
        if (state.status === 'success') {
          setFoodProduct(state.payload.data)
        }
      })

    return () => subscriber.unsubscribe()
  }, [foodProductsService, id])

  useEffect(() => {
    if (!foodProduct) {
      return
    }

    form.setFieldsValue(foodProduct)
  }, [foodProduct, form])

  const handleFormChanged = () => {
    const product = form.getFieldsValue()
    handleServingQuantityChange(product)
    handlePackageQuantityChange(product)

    setServing(Boolean(product.isServing))
    setPackage(Boolean(product.isPackage))
  }

  const handleServingQuantityChange = (product: FoodProductData) => {
    const value = Number(product.servingQuantity)
    if (isNaN(value)) {
      return
    }

    const macros = getMacrosByQuantity(value, product)
    form.setFieldsValue({ energyValueServing: macros.energyValue })
    form.setFieldsValue({ servingSize: `${value} gram` })
    form.setFieldsValue({ proteinsServing: macros.proteins })
    form.setFieldsValue({ fatsServing: macros.fats })
    form.setFieldsValue({ carbohydratesServing: macros.carbohydrates })
    form.setFieldsValue({ fiberServing: macros.fiber })
    form.setFieldsValue({ sugarServing: macros.sugar })
    form.setFieldsValue({ saltServing: macros.salt })
  }

  const handlePackageQuantityChange = (product: FoodProductData) => {
    const value = Number(product.packageQuantity)
    if (isNaN(value)) {
      return
    }

    const macros = getMacrosByQuantity(value, form.getFieldsValue())
    form.setFieldsValue({ energyValuePackage: macros.energyValue })
    form.setFieldsValue({ packageSize: `${value} gram` })
    form.setFieldsValue({ proteinsPackage: macros.proteins })
    form.setFieldsValue({ fatsPackage: macros.fats })
    form.setFieldsValue({ carbohydratesPackage: macros.carbohydrates })
    form.setFieldsValue({ fiberPackage: macros.fiber })
    form.setFieldsValue({ sugarPackage: macros.sugar })
    form.setFieldsValue({ saltPackage: macros.salt })
  }

  const handleFinish = useEventHandler(
    (values: FoodProductData) => {
      if (!id || !foodProduct) {
        return EMPTY.subscribe()
      }

      return foodProductsService
        .update(id, { ...foodProduct, ...values })
        .pipe(toResult())
        .subscribe(state => {
          setUpdating(state.status === 'loading')
          if (state.status === 'success') {
            message.success(`Product "${values.name}" has been updated`)
            history.goBack()
          }
        })
    },
    [foodProductsService, foodProduct, id]
  )

  const handleRemovePress = useEventHandler(() => {
    if (!id || !foodProduct) {
      return EMPTY.subscribe()
    }
    return foodProductsService
      .delete(id)
      .pipe(toResult())
      .subscribe(state => {
        setUpdating(state.status === 'loading')
        if (state.status === 'success') {
          message.success(`Product "${foodProduct.name}" has been removed`)
          history.goBack()
        }
      })
  }, [foodProductsService, foodProduct, id])

  return (
    <AuthorizedLayout>
      <Row style={{ margin: '32px 0 8px 0' }}>
        <Col xs={{ span: 20, offset: 2 }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={ROUTE.ADMIN_FOOD_PRODUCTS}>Food Products</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Edit</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Divider />
      <StyledRow>
        {isLoading ? (
          <Col xs={{ span: 20, offset: 2 }}>
            <Spin size="large" />
          </Col>
        ) : (
          <Col xs={{ span: 20, offset: 2 }}>
            <Form form={form} name="control-hooks" onFinish={handleFinish} onChange={() => handleFormChanged()}>
              <Form.Item
                labelCol={{ xs: { span: 4 } }}
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Field required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item labelCol={{ xs: { span: 4 } }} label="Brand" name="brand">
                <Input />
              </Form.Item>
              <Form.Item labelCol={{ xs: { span: 4 } }} label="Barcode" name="barcode">
                <InputNumber style={{ width: '200px' }} />
              </Form.Item>
              <Form.Item labelCol={{ xs: { span: 4 } }} label="Created by" name="createdBy">
                <Typography.Text>{foodProduct?.createdBy}</Typography.Text>
              </Form.Item>
              <Form.Item labelCol={{ xs: { span: 4 } }} label="Created at" name="createdAt">
                <Typography.Text>
                  {foodProduct?.createdAt ? format(new Date(foodProduct.createdAt), 'dd-MM-yyyy / HH:ii') : '-'}
                </Typography.Text>
              </Form.Item>

              <Form.Item labelCol={{ xs: { span: 4 } }} label="Public" name="isPublic" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item labelCol={{ xs: { span: 4 } }} label="To verify" name="toVerify" valuePropName="checked">
                <Switch />
              </Form.Item>

              <Row>
                <Col span={8}>
                  <Space direction="vertical" style={{ width: '95%' }}>
                    <Card title="100 gram">
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Kcal" name="energyValue">
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Proteins" name="proteins">
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Fats" name="fats">
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Carbs." name="carbohydrates">
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Fiber" name="fiber">
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Salt" name="salt">
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Sugar" name="sugar">
                        <InputNumber min={0} />
                      </Form.Item>
                    </Card>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical" style={{ width: '95%' }}>
                    <Card
                      title="Serving"
                      extra={
                        <Form.Item labelCol={{ xs: { span: 4 } }} name="isServing" valuePropName="checked">
                          <Switch onChange={handleFormChanged} />
                        </Form.Item>
                      }
                    >
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Gram" name="servingQuantity">
                        <InputNumber disabled={!isServing} min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Kcal" name="energyValueServing">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Proteins" name="proteinsServing">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Fats" name="fatsServing">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Carbs." name="carbohydratesServing">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Fiber" name="fiberServing">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Salt" name="saltServing">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Sugar" name="sugarServing">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                    </Card>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="vertical" style={{ width: '95%' }}>
                    <Card
                      title="Package"
                      extra={
                        <Form.Item labelCol={{ xs: { span: 4 } }} name="isPackage" valuePropName="checked">
                          <Switch onChange={handleFormChanged} />
                        </Form.Item>
                      }
                    >
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Gram" name="packageQuantity">
                        <InputNumber disabled={!isPackage} min={0} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Kcal" name="energyValuePackage">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Proteins" name="proteinsPackage">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Fats" name="fatsPackage">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Carbs." name="carbohydratesPackage">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Fiber" name="fiberPackage">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Salt" name="saltPackage">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                      <Form.Item labelCol={{ xs: { span: 8 } }} label="Sugar" name="sugarPackage">
                        <InputNumber disabled precision={3} />
                      </Form.Item>
                    </Card>
                  </Space>
                </Col>
              </Row>

              <Form.Item style={{ marginTop: '24px', marginBottom: '40px' }}>
                {isUpdating ? (
                  <Spin />
                ) : (
                  <Space>
                    <Button type="primary" htmlType="submit" size="large">
                      Update
                    </Button>

                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={handleRemovePress}
                      okText="Remove"
                      cancelText="Cancel"
                      okButtonProps={{ danger: true }}
                    >
                      <Button danger={true} size="large">
                        Remove
                      </Button>
                    </Popconfirm>
                  </Space>
                )}
              </Form.Item>
            </Form>
          </Col>
        )}
      </StyledRow>
    </AuthorizedLayout>
  )
}

export default AdminFoodProductsEditRoute
