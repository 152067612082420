import { useMemo, useEffect, useRef, useCallback } from 'react'
import { Subject, Subscription, fromEventPattern } from 'rxjs'
import { switchMap } from 'rxjs/operators'

function useEventHandler(input: () => Subscription, deps?: readonly any[] | undefined): () => void
function useEventHandler<T>(input: (arg0: T) => Subscription, deps?: readonly any[] | undefined): (arg0: T) => void
function useEventHandler<T, X>(
  input: (arg0: T, arg1: X) => Subscription,
  deps?: readonly any[] | undefined
): (arg0: T, arg1: X) => void
function useEventHandler<T, X, Y>(
  input: (arg0: T, arg1: X, arg2: Y) => Subscription,
  deps?: readonly any[] | undefined
): (arg0: T, arg1: X, arg2: Y) => void
function useEventHandler<T>(input: (...args: T[]) => Subscription, deps: readonly any[] | undefined = []) {
  const subject = useMemo(() => new Subject(), [])
  const values = useRef<T[]>([])
  const handler = useCallback((...args: T[]): void => {
    values.current = args
    subject.next(0)
  }, [])

  useEffect(() => {
    const subscription = subject
      .pipe(
        switchMap(() =>
          fromEventPattern(
            () => input(...values.current),
            (_handler, signal) => signal.unsubscribe()
          )
        )
      )
      .subscribe()

    return () => subscription.unsubscribe()
  }, deps)

  return handler
}

export default useEventHandler
