import { useState, useEffect } from 'react'

import { Button, Form, Input, Radio, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { CreateArticleOptions } from 'services/Articles/types'

import * as screenfull from 'screenfull'

import './formStyle.css'

const LabelColConfig = { xs: { span: 4 }, md: { span: 6 } }

interface Props {
  form: FormInstance<CreateArticleOptions>
  onFinish: (values: CreateArticleOptions) => void
}

interface TextEditorProps {
  value?: string
  onChange?: (e: any) => void
}
const TextEditor = (props: TextEditorProps) => {
  const [isQuillReady, setQuillReady] = useState(false)

  const modules = {
    toolbar: [
      [{ header: [false, 2, 3] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['omega']
    ]
  }

  const formats = ['bold', 'font', 'italic', 'underline', 'header', 'list', 'omega', 'handlers']

  useEffect(() => {
    const quillEditorField = document.querySelector('.quill')
    const customButton = document.querySelector('.ql-omega')

    if (isQuillReady) {
      return
    }

    const interval = setInterval(() => {
      if (!quillEditorField || !customButton) {
        return
      }
      setQuillReady(true)

      customButton.addEventListener('click', function () {
        if (screenfull.isEnabled) {
          screenfull.request(quillEditorField).catch(error => console.log('err', error))
        }
      })
    }, 500) // check every 0.5 sec

    return () => clearInterval(interval)
  }, [isQuillReady])

  //     customButton.addEventListener('click', function () {
  //       if (screenfull.isEnabled) {
  //         screenfull.request(quillEditorField).catch(error => console.log('err', error))
  //       } else {
  //         console.log('Screenfull not enabled')
  //       }
  //     })
  //   }, 2000)
  // }, [])

  return (
    <ReactQuill theme="snow" value={props.value || ''} modules={modules} formats={formats} onChange={props.onChange} />
  )
}

export const FormAddEdit = (props: Props) => {
  const { onFinish, form } = props

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        labelCol={LabelColConfig}
        label="Author Name"
        name="authorName"
        rules={[
          { required: true, message: 'Field required' },
          { min: 3, message: 'Name is too  short' }
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Author Surname"
        name="authorSurname"
        rules={[
          { required: true, message: 'Field required' },
          { min: 2, message: 'Surname is too  short' }
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item labelCol={LabelColConfig} label="Avatar" name="authorAvatar" rules={[{ required: false }]}>
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Short Description"
        name="authorShortDescription"
        rules={[
          { required: true, message: 'Field required' },
          { min: 10, message: 'Description is too  short' }
        ]}
      >
        <Input.TextArea rows={1} placeholder="" />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Long Description"
        name="authorLongDescription"
        rules={[{ required: false }]}
      >
        <Input.TextArea rows={2} placeholder="" />
      </Form.Item>
      <Form.Item labelCol={LabelColConfig} label="Image" name="image" rules={[{ required: false }]}>
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Lang"
        name="lang"
        rules={[{ required: true, message: 'Field required' }]}
      >
        <Radio.Group>
          <Radio value={'pl'}>PL</Radio>
          <Radio value={'en'}>ENG</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Promo"
        name="promo"
        valuePropName="checked"
        rules={[{ required: false }]}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Section"
        name="section"
        rules={[{ required: true, message: 'Field required' }]}
      >
        <Radio.Group>
          <Radio value="workouts">Workouts</Radio>
          <Radio value="nutritions">Nutritions</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item labelCol={LabelColConfig} label="Slug" name="slug" rules={[{ required: false }]}>
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Title"
        name="title"
        rules={[
          { required: true, message: 'Field required' },
          { min: 10, message: 'Title is too  short' }
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Text"
        name="text"
        rules={[
          { required: true, message: 'Field required' },
          { min: 100, message: 'Text is too  short' }
        ]}
      >
        <TextEditor />
      </Form.Item>
      <Form.Item
        labelCol={LabelColConfig}
        label="Tags"
        name="tags"
        rules={[{ required: true, message: 'Field required' }]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
