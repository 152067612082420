export type Category =
  | 'neck'
  | 'shoulders'
  | 'upper-arms'
  | 'forearms'
  | 'back'
  | 'chest'
  | 'waist'
  | 'hips'
  | 'thighs'
  | 'calves'
  | 'other'

export type Tag = 'calisthenics' | 'cardio' | 'balance' | 'gym' | 'abs' | 'crossfit' | 'plyometrics' | 'kettlebell'
export type Force = 'functional' | 'push' | 'pull'

export type ExerciseData = {
  objectID: string
  name: string
  description: string
  locales?: {
    pl?: {
      name: string
      description: string
    }
    en?: {
      name: string
      description: string
    }
  }
  category: Category
  force: Force
  tags: Array<Tag>
  isDeleted?: boolean
  isPublic?: boolean
  detailsUrl?: string
  createdAt: Date
  createdBy: string
  updatedAt?: Date
  updatedBy?: string
}

export const Forces: Force[] = ['functional', 'pull', 'push']
export const Tags: Tag[] = ['abs', 'balance', 'calisthenics', 'cardio', 'crossfit', 'gym', 'kettlebell', 'plyometrics']
export const Categories: Category[] = [
  'neck',
  'shoulders',
  'upper-arms',
  'forearms',
  'back',
  'chest',
  'waist',
  'hips',
  'thighs',
  'calves',
  'other'
]
