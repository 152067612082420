import { omit } from 'remeda'
import { ROUTE } from 'routers/types'
import { from, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import Api from 'services/Api'
import { ExerciseData } from './types'

class Exercises {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  getList(
    limit = 10,
    offset = 0,
    sort?: keyof ExerciseData,
    order: 'ascend' | 'descend' = 'ascend',
    onlyPublic = false
  ) {
    const searchParams = new URLSearchParams({ limit: limit.toString(), offset: offset.toString() })
    if (sort && order) {
      searchParams.append('sort', sort)
      searchParams.append('order', order)
    }

    const url = onlyPublic ? '/exercises/public' : '/exercises'

    const query = searchParams.toString()
    return from(this.api.fetchAuth(`${url}?${query}`, { method: 'GET' })).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  search(keyword: string): Observable<{ data: ExerciseData[] }> {
    return from(this.api.fetchAuth(`/exercises/search?q=${keyword}`, { method: 'GET' })).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  getOne(id: string) {
    return from(this.api.fetchAuth(`/exercises/${id}`, { method: 'GET' })).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  update(objectId: string, values: ExerciseData) {
    if (!values.name || !values.category || !values.force || !values.tags || values.tags.length === 0) {
      throw new Error('Invalid update exercise data')
    }

    return from(
      this.api.fetchAuth(`/exercises/${objectId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          ...omit(values, ['objectID']),
          updatedAt: new Date()
        })
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  create(values: ExerciseData) {
    if (!values.name || !values.category || !values.force || !values.tags || values.tags.length === 0) {
      throw new Error('Invalid update exercise data')
    }

    return from(
      this.api.fetchAuth(`/exercises`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          ...omit(values, ['objectID']),
          isPublic: values.isPublic
        })
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }

  delete(id: string) {
    return from(
      this.api.fetchAuth(`/exercises/${id}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json'
        }
      })
    ).pipe(
      map(response => {
        if (response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          location.pathname = ROUTE.ADMIN_LOGIN
          localStorage.clear()
          throw new Error('Unauthorized')
        }

        return response
      }),
      switchMap(response => response.json()),
      map(response => {
        if (response.message) {
          throw new Error(response.message)
        }

        return response
      })
    )
  }
}

export default Exercises
