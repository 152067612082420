import { Observable, of } from 'rxjs'
import { map as map$, startWith, catchError } from 'rxjs/operators'

import { StateError, Loading, Success, Error, FirebaseError } from './types'

export const mapToStateError = (err: any): StateError => {
  switch (err.code) {
    case 'auth/email-already-in-use':
      return FirebaseError.AuthEmailAlreadyInUse
    case 'auth/wrong-password':
      return FirebaseError.AuthWrongPassword
    case 'auth/user-disabled':
      return FirebaseError.AuthUserDisabled
    case 'auth/user-not-found':
      return FirebaseError.AuthUserNotFound
    case 'auth/credential-already-in-use':
      return FirebaseError.AuthCredentialAlreadyInUse
    case 'auth/too-many-requests':
      return FirebaseError.AuthTooManyRequests
    case 'auth/account-exists-with-different-credential':
      return FirebaseError.AuthExistWithDifferentCredential
    case 'auth/invalid-verification-code':
      return FirebaseError.AuthInvalidVerificationCode
    case 'auth/invalid-phone-number':
      return FirebaseError.AuthInvalidPhoneNumber
    case 'auth/invalid-email': {
      return FirebaseError.AuthInvalidEmailAddress
    }
    default:
      return err.message as StateError
  }
}

export const loading = () => ({ status: 'loading' } as Loading)
export const success = <T>(payload: T) => ({ status: 'success', payload } as Success<T>)
export const error = (payload: StateError) => ({ status: 'error', payload } as Error)

export const toResult = <T>() => (observable: Observable<T>) =>
  observable.pipe(
    map$((data: T) => success(data)),
    startWith(loading()),
    catchError((err: any) => {
      // eslint-disable-next-line no-console
      process.env.NODE_ENV === 'development' && console.log('___ERROR', err.message, err.code, err.stack)
      return of(error(mapToStateError(err)))
    })
  )
