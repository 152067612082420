import useServices from 'hooks/useServices'
import { useEffect, useState } from 'react'
import { CurrentUser } from 'services/types'

export const useCurrentUser = () => {
  const { auth } = useServices()

  const [user, setUser] = useState<CurrentUser | null | undefined>()

  useEffect(() => {
    const subscriber = auth.user.subscribe(setUser)
    return () => subscriber.unsubscribe()
  }, [auth.user])

  return { isLoaded: user !== undefined, currentUser: user || null }
}
